
































































/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";

import { AuditMetadataClient } from "@/types/Audit";

import { api } from "@/store/modules/audit";
import { api as appApi } from "@/store/modules/app";
import { api as auditResultApi } from "@/store/modules/auditResult";
import { api as configApi } from "@/store/modules/configuration";

import {
  scoreToComplianceScore,
  scoreToCompliancePercent,
} from "@auditcloud/shared/lib/types/AuditScore";

import AProgressBar from "@/components/controls/AProgressBar.vue";
import AAuditOverview from "@/components/widgets/AAuditOverview.vue";
import AAuditItemsListFilter from "@/components/widgets/AAuditItemsListFilter.vue";
import AReportRequestConfigurationDialog from "@/components/dialogs/AReportRequestConfigurationDialog.vue";
import ACardWithHeader from "@/components/widgets/ACardWithHeader.vue";
import AAuditResultWidget from "@/components/widgets/AAuditResultWidget.vue";
import {
  WorkflowActionPayload,
  WorkflowActionIds,
  WorkflowAction,
} from "@auditcloud/shared/lib/types/WorkflowActions";

// Export
import moment from "moment";
import requestPdfReport, { PdfReportRequest } from "@/utils/requestPdfReport";
import generateXlsxReport from "@/utils/generateXlsxReport";
import { nullable, idable } from "@auditcloud/shared/lib/types/common";
import { createError } from "../utils/Errors";
import {
  AuditItem,
  ExcelExportConfig,
  ReportPageConfig,
} from "@auditcloud/shared/lib/schemas";
import { TodoMap } from "@auditcloud/shared/lib/utils/type-guards";
import { DIALOG_NAMES, dialogRoute, ROUTE_NAMES } from "@/routenames";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { getterNs } from "@/utils/VuexHelper";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { ScoreCalcSteps } from "@/store/modules/auditResult/getters";
import {
  AuditReportSummary,
  FlatReportData,
  ExcelMetadataCollection,
  AuditItemsInConflictState,
} from "@/store/modules/audit/getters";
import { contentLanguage } from "@/plugins/ContentTranslation";
import { cloneDeep, entries } from "lodash";
import { AuditReportExportData } from "@auditcloud/shared/lib/types/AuditReportExport";

@Component({
  components: {
    AAuditOverview,
    AAuditResultWidget,
    AProgressBar,
    AAuditItemsListFilter,
    ACardWithHeader,
    AReportRequestConfigurationDialog,
  },
})
export default class AAuditReporting extends Vue {
  isDownloadDialogOpen: boolean = false;
  pdfRequest: null | PdfReportRequest = null;

  get filenameXlsx() {
    return `Report_${moment().format("YYYY-MM-DD")}_${formatAuditName(
      this.auditMetadata
    )}.xlsx`;
  }

  get filenamePdf() {
    return `Report_${moment().format("YYYY-MM-DD")}_${formatAuditName(
      this.auditMetadata
    )}.pdf`;
  }

  // Export Pdf

  @Getter(configApi.getters.configurableReportFeatureEnabled, {
    namespace: configApi.namespace,
  })
  configurableReportFeatureEnabled!: boolean;

  @Getter(configApi.getters.audit, {
    namespace: configApi.namespace,
  })
  audit!: TodoMap;

  openReportingDialog() {
    const params: any = this.$router.currentRoute.params;
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;

    this.$router.push({
      name: dialogRoute(routeName, DIALOG_NAMES.REPORTING_DIALOG),
      params: {
        auditId,
      },
    });
  }

  get complianceScore() {
    const inTotal = this.currentAuditReportSummary?.score.inTotal;
    return inTotal ? scoreToComplianceScore(inTotal) : 0;
  }

  @Getter(auditResultApi.getters.getAuditReportExportData, {
    namespace: auditResultApi.namespace,
  })
  exportData!: AuditReportExportData;

  get generatePdf() {
    console.log("generatePdf", this.pdfRequest);
    if (this.pdfRequest === null) {
      return () => Promise.reject(createError("Request Data is null"));
    }
    if (this.pdfRequest.pages.length === 0) {
      return () => Promise.reject(createError("No report pages configured"));
    }
    return requestPdfReport(this.pdfRequest);
  }

  get actionPlanExcelExportConfig(): ExcelExportConfig {
    if (this.auditClass !== null && this.auditClass.actionListExportConfig) {
      return this.auditClass.actionListExportConfig;
    } else {
      return {
        templateName: this.audit.exportTemplate,
        contentListSheetName: this.audit.exportSheetName || "Pruefungsbericht",
        reportSheetName: this.audit.exportReportSheetName || "Report",
        contentListConfig: {
          offset: this.audit.exportSheetOffset || 6,
          columNames: this.audit.exportSheetCols || [],
        },
      };
    }
  }

  get currentExcelExport() {
    const result = this.currentAuditReportSummary;

    let output = [["Key", "Value"]];
    const scalar_fields = [
      "auditId",
      "auditName",
      "auditReportingType",
      "auditStandard",
      "auditType",
      "auditedBy",
      "auditPlaningDate",
      "auditPlanningYear",
      "auditingDates",
    ];
    for (let key of scalar_fields) {
      output.push([key, result?.[key] ?? ""]);
    }

    const statistics_fields = [
      "auditItemCount",
      "auditFindingsCount",
      "auditMeasuresCount",
    ];
    for (let key of statistics_fields) {
      output.push([key, result?.statistics?.[key] ?? ""]);
    }
    output.push([]);
    output.push([
      "Category",
      "worst Score",
      "current Score",
      "best Score",
      "Compliance %",
    ]);

    const inTotal = result?.score.inTotal;
    output.push([
      "Total Score",
      String(result?.score.inTotal.worst ?? ""),
      String(result?.score.inTotal.current ?? ""),
      String(result?.score.inTotal.best ?? ""),
      String(inTotal ? scoreToCompliancePercent(inTotal) : ""),
    ]);
    output.push([]);
    for (let score of result?.score.byCategory ?? []) {
      output.push([
        score.name,
        String(score.worst),
        String(score.current),
        String(score.best),
        String(scoreToCompliancePercent(score)),
      ]);
    }
    return output;
  }

  get next() {
    return {
      name: "wrapup",
      params: {
        auditId: this.auditId,
      },
    };
  }

  get prev() {
    return {
      name: "wrapup",
      params: {
        auditId: this.auditId,
      },
    };
  }

  @Getter(api.getters.getReportPages, { namespace: api.namespace })
  reportPages!: ReportPageConfig[];

  //missingDefault
  @Prop({
    type: String,
  })
  readonly auditId!: string;

  isIntersecting: boolean = false;
  offsetTop: number = 0;

  onScroll(e: any) {
    if (window.pageYOffset > 70) {
      this.isIntersecting = true;
    } else {
      this.isIntersecting = false;
    }
  }

  /*@Prop({
    type: Object
  })
  readonly audit!: any;*/

  @Getter(api.getters.getAuditMetadata, {
    namespace: api.namespace,
  })
  auditMetadata!: AuditMetadataClient | null;

  @Getter(api.getters.getAuditClass, {
    namespace: api.namespace,
  })
  auditClass!: AuditClassClient | null;

  @Getter(api.getters.getAuditReportSummary, {
    namespace: api.namespace,
  })
  currentAuditReportSummary!: AuditReportSummary;

  @Getter(api.getters.getFlatReportData, {
    namespace: api.namespace,
  })
  flatFindingsList!: FlatReportData;

  @Getter(api.getters.getIsResultVisible, {
    namespace: api.namespace,
  })
  isResultVisible!: boolean;

  @Getter(api.getters.getAuditItems, {
    namespace: api.namespace,
  })
  auditItems!: Array<idable<AuditItem>>;
  @Getter(api.getters.getExcelMetadataCollection, {
    namespace: api.namespace,
  })
  excelMetadataCollection!: ExcelMetadataCollection;

  @Getter(api.getters.getUnAnsweredAuditItems, {
    namespace: api.namespace,
  })
  unanswerdAuditItems!: Array<idable<AuditItem>>;

  @Getter(api.getters.getAuditItemsInConflictState, {
    namespace: api.namespace,
  })
  auditItemsInConflictState!: AuditItemsInConflictState;

  @Getter(api.getters.getUnassignedFindingsCount, {
    namespace: api.namespace,
  })
  freeFindingsCount!: number;

  get auditItemCount() {
    return this.auditItems.length;
  }

  get answerdAuditItemCount() {
    return this.auditItemCount - this.unanswerdAuditItems.length;
  }

  get auditItemsIdsWithConflict(): string[] {
    const auditItemsIdsWithConflict = entries(this.auditItemsInConflictState)
      .filter(([, conflictInfo]) => {
        return conflictInfo !== null && conflictInfo.length > 0;
      })
      .map(([auditItemId]) => auditItemId);
    return auditItemsIdsWithConflict;
  }

  downloadBlob: null | Blob = null;
  blobUrl: nullable<string> = null;

  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  setLoading!: (v: boolean) => Promise<any>;
  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  addStatus!: (payload: { message: string; type: "error" }) => Promise<any>;

  //  Export Excel
  downloadXlsx(useLocalTemplate: boolean) {
    const self = this;

    const packedData = {
      currentExcelExport: this.currentExcelExport,
      currentAuditReportSummary: this.$store.getters[
        getterNs(api, api.getters.getAuditReportSummary)
      ] as AuditReportSummary,
      currentCalcScore: this.$store.getters[
        getterNs(auditResultApi, auditResultApi.getters.getScoreCalcSteps)
      ] as ScoreCalcSteps,
    };

    generateXlsxReport(
      this.excelMetadataCollection,
      this.flatFindingsList,
      this.actionPlanExcelExportConfig,
      useLocalTemplate,
      this.ContentTranslation.language
    )()
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        self.downloadBlob = blob;
        self.blobUrl = url;

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = self.blobUrl;
        a.download = self.filenameXlsx;
        a.click();
        document.body.removeChild(a);
      })
      .catch(err => {
        console.log("Download failed", err);
        //self.addStatus({ message: "Download failed", type: "error" });
      });
  }

  triggerDownloadPdf() {
    if (this.exportData === null) {
      return () => Promise.reject(createError("Report export data is null"));
    }
    this.pdfRequest = {
      templateName: "report",
      language: contentLanguage(),
      reportData: this.exportData,
      pages: cloneDeep(this.reportPages),
    };
    if (this.configurableReportFeatureEnabled) {
      this.isDownloadDialogOpen = true;
    } else {
      this.downloadPdf();
    }
  }

  @Watch("$route", {
    immediate: true,
    deep: true,
  })
  onWatchRoute(newVal, oldVal) {
    if (
      oldVal &&
      newVal.name === ROUTE_NAMES.AUDITREPORTING &&
      oldVal.params.dialogName === DIALOG_NAMES.PRINT_FILTER_DIALOG
    ) {
      this.isDownloadDialogOpen = true;
    }
  }

  downloadPdf() {
    this.isDownloadDialogOpen = false;
    this.setLoading(true);
    const self = this;
    const pdfGenerator = this.generatePdf;

    pdfGenerator()
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        self.downloadBlob = blob;
        self.blobUrl = url;

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = self.blobUrl;
        a.download = self.filenamePdf;
        a.click();
        document.body.removeChild(a);
        self.setLoading(false);
      })
      .catch(err => {
        console.log("Download failed", err);
        self.addStatus({ message: "Download failed", type: "error" });
        self.setLoading(false);
      });
  }

  @Getter(api.getters.getActionsPerView, { namespace: api.namespace })
  actionsPerView!: (viewname: string) => string[];

  get actionMap(): WorkflowActionPayload {
    const actionMap: { [id: string]: WorkflowAction } = {
      [WorkflowActionIds.PREPARE_REPORT]: {
        id: WorkflowActionIds.PREPARE_REPORT,
        text: `${this.$t("views.audit_reporting.report_edit")}`,
        icon: "notes",
        handler: () => {
          console.log("Report bearbeiten");
          this.openReportingDialog();
          //alert(this.auditId);
        },
      },
    };

    if (this.isResultVisible) {
      actionMap[WorkflowActionIds.REPORT] = {
        id: WorkflowActionIds.REPORT,
        text: `${this.$t("views.audit_reporting.report_download")}`,
        icon: "vertical_align_bottom",
        handler: () => {
          this.triggerDownloadPdf();
        },
      };
      actionMap[WorkflowActionIds.ACTIONPLAN] = {
        id: WorkflowActionIds.ACTIONPLAN,
        text: `${this.$t("views.audit_reporting.actionplan_download")}`,
        icon: "notes",
        handler: () => {
          this.downloadXlsx(false);
        },
      };
    }

    if (this.$hasFeature("featureCustomActionPlanTemplate")) {
      actionMap[WorkflowActionIds.ACTIONPLAN_LOCAL_TEMPLATE] = {
        id: WorkflowActionIds.ACTIONPLAN_LOCAL_TEMPLATE,
        text: `${this.$t("views.audit_reporting.actionplan_template_usage")}`,
        icon: "notes",
        handler: () => {
          this.downloadXlsx(true);
        },
      };
    }

    const actionsList = this.actionsPerView("reporting")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };
    return actions;
  }

  @Watch("actionMap", { immediate: true, deep: true })
  onActionMapChanged(actionMap: WorkflowActionPayload) {
    console.log("onActionMapChanged", this.isResultVisible, actionMap);
    this.$emit("actions", actionMap);
  }

  mounted() {
    this.$vuetify.goTo(0);
  }
}
